<template>
  <component
    v-show="isSidebarOpen || isSidebarComponentLoaded"
    :is="theSidebarAsyncComponent"
    @close="$store.commit('ui/setSidebar', false)"
    @reload="reloadComponent()"
  />
</template>

<script>
import { mapState } from 'vuex';
import ALoadingSpinner from '~/theme/components/atoms/a-loading-spinner';
import ALoadingError from '~/theme/components/atoms/a-loading-error';

const OSidebarPanel = () =>
  import(/* webpackChunkName: "vsf-sidebar" */ '~/theme/components/organisms/o-sidebar-panel');

export default {
  data () {
    return {
      isSidebarComponentLoaded: false,
      theSidebarAsyncComponent: () => ({
        component: OSidebarPanel().then(component => {
          this.isSidebarComponentLoaded = true;
          return component;
        }),
        loading: ALoadingSpinner,
        error: ALoadingError,
        timeout: 3000
      })
    };
  },
  computed: {
    ...mapState({
      isSidebarOpen: state => state.ui.sidebar
    })
  },
  methods: {
    reloadComponent () {
      this.theSidebarAsyncComponent = () => ({
        component: OSidebarPanel().then(component => {
          this.isSidebarComponentLoaded = true;
          return component;
        }),
        loading: ALoadingSpinner,
        error: ALoadingError,
        timeout: 3000
      });
    }
  }
};
</script>
